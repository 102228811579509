/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // hover for parent / child megamenu columns
        $('#menu-thingstodo').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('.dropdown-menu .menu-thingstodo').addClass('active-child');
             },
             function() {
                 $(this).removeClass('active-parent');
                 $('.dropdown-menu .menu-thingstodo').removeClass('active-child');
             }
        );
        $('#menu-healthfitness').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('.dropdown-menu .menu-healthfitness').addClass('active-child');
             },
             function() {
                 $(this).removeClass('active-parent');
                 $('.dropdown-menu .menu-healthfitness').removeClass('active-child');
             }
        );
        $('#menu-familieskids').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('.dropdown-menu .menu-familieskids').addClass('active-child');
             },
             function() {
                 $(this).removeClass('active-parent');
                 $('.dropdown-menu .menu-familieskids').removeClass('active-child');
             }
        );
        $('#menu-placestoeat').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('.dropdown-menu .menu-placestoeat').addClass('active-child');
             },
             function() {
                 $(this).removeClass('active-parent');
                 $('.dropdown-menu .menu-placestoeat').removeClass('active-child');
             }
        );
        $('#menu-golfactivities').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('.dropdown-menu .menu-golfactivities').addClass('active-child');
             },
             function() {
                 $(this).removeClass('active-parent');
                 $('.dropdown-menu .menu-golfactivities').removeClass('active-child');
             }
        );


        // and reversed for highlighting the parent items when child columns are hovered
        $('.dropdown-menu .menu-thingstodo').hover(
             function() {
                 $(this).addClass('active-child');
                 $('#menu-thingstodo').addClass('active-parent');
             },
             function() {
                 $(this).removeClass('active-child');
                 $('#menu-thingstodo').removeClass('active-parent');
             }
        );
        $('.dropdown-menu .menu-healthfitness').hover(
             function() {
                 $(this).addClass('active-child');
                 $('#menu-healthfitness').addClass('active-parent');
             },
             function() {
                 $(this).removeClass('active-child');
                 $('#menu-healthfitness').removeClass('active-parent');
             }
        );
        $('.dropdown-menu .menu-familieskids').hover(
             function() {
                 $(this).addClass('active-child');
                 $('#menu-familieskids').addClass('active-parent');
             },
             function() {
                 $(this).removeClass('active-child');
                 $('#menu-familieskids').removeClass('active-parent');
             }
        );
        $('.dropdown-menu .menu-placestoeat').hover(
             function() {
                 $(this).addClass('active-parent');
                 $('#menu-placestoeat').addClass('active-parent');
             },
             function() {
                 $(this).removeClass('active-child');
                 $('#menu-placestoeat').removeClass('active-parent');
             }
        );
        $('.dropdown-menu .menu-golfactivities').hover(
             function() {
                 $(this).addClass('active-child');
                 $('#menu-golfactivities').addClass('active-parent');
             },
             function() {
                 $(this).removeClass('active-child');
                 $('#menu-golfactivities').removeClass('active-parent');
             }
        );


        // mobile nav sliding animation for bootstrap toggles
        $('.mobile-collapse .dropdown .dropdown-toggle').on('click', function(e) {
          e.preventDefault();
          if ($(window).width() < 991) {
            $(this).parent().find('.dropdown-menu').slideToggle(300);
          }
        });


        // support for Bootstrap mobile submenu toggles
        $('.mobile-collapse .dropdown-item').on('click', function (e) {
            var $el = $(this).children('.dropdown-toggle');
            var $parent = $el.offsetParent(".dropdown-menu");
            $(this).parent("li").toggleClass('open');

            if (!$parent.parent().hasClass('mobile-collapse')) {
                if ($parent.hasClass('open')) {
                    $parent.removeClass('open');
                    $el.next().removeClass('open');
                    $el.next().css({"top": -999, "left": -999});
                } else {
                    $parent.parent().find('.open').removeClass('open');
                    $parent.addClass('open');
                    $el.next().addClass('open');
                    $el.next().css({"top": $el[0].offsetTop, "left": $parent.outerWidth() - 4});
                }
                e.preventDefault();
                e.stopPropagation();
            }
        });
        $('.mobile-collapse .dropdown').on('hidden.bs.dropdown', function () {
            $(this).find('li.dropdown').removeClass('open');
            $(this).find('ul.dropdown-menu').removeClass('open');
        });


        // hamburger mobile animation
        var hamburgers = document.querySelectorAll(".hamburger");
        if (hamburgers.length > 0) {
          forEach(hamburgers, function(hamburger) {
            hamburger.addEventListener("click", function() {
              this.classList.toggle("is-active");
            }, false);
          });
        }


        // By default submit is disabled unless user adds search term (prevents empty searches)
        $('.btn-search-holder').prop('disabled', true);
        $('.input-text-rounded').keyup(function() {
          if($(this).val().length !=0 ) {
            $('.btn-search-holder').prop('disabled', false);
          } else {
            $( ".input-text-rounded").focus();
              $('.btn-search-holder').prop('disabled', true);
              e.preventDefault();
          }
        });


        // owl carousel - footer promotions
        var fbp_slider = $('.carousel-footer-promos');
        fbp_slider.owlCarousel({
            autoplay: false,
            lazyLoad:true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            mouseDrag: false,
            touchDrag: false,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: $(".carousel-footer-promos .owl-item").length <= 2 ? false : true,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // custom scrollbar for carousel
        $(".carousel-footer-promos").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse control (annoying on full-width carousels as it interferes with page scroll)
        });


        // owl carousel - footer awards
        var faw_slider = $('.carousel-footer-awards');
        faw_slider.owlCarousel({
            autoplay: true,
            lazyLoad:true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            items: 4,
            nav: true,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: true,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 0,
                loop: false,
                nav: false,
                autoplay: false,
            },
            767:{
                items:2,
                margin: 20,
                loop: false,
                nav: false,
                autoplay: false,
            },
            991:{
                items:4,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        if ($(window).width() < 992) { // award carousel loads a scrollbar when on tablet/mobile only on page load
          $(".carousel-footer-awards").mCustomScrollbar({
              axis:"x",
              theme:"dark-thick",
              autoExpandScrollbar:true,
              advanced:{autoExpandHorizontalScroll:true},
              updateOnContentResize:true,
              scrollbarPosition: 'outside',
              scrollInertia: 200,
              mouseWheel: 'disable' // disable mouse control (annoying on full-width carousels as it interferes with page scroll)
          });
        }
        $(window).resize(function() { // recalculate on browser resize
          if ($(window).width() < 992) { // award carousel loads a scrollbar when on tablet/mobile only
            $(".carousel-footer-awards").mCustomScrollbar({
                axis:"x",
                theme:"dark-thick",
                autoExpandScrollbar:true,
                advanced:{autoExpandHorizontalScroll:true},
                updateOnContentResize:true,
                scrollbarPosition: 'outside',
                scrollInertia: 200,
                mouseWheel: 'disable' // disable mouse control (annoying on full-width carousels as it interferes with page scroll)
            });
          } else {

          }
        });


        // owl carousel - testimonials
        var testmionial_slider = $('.carousel-testimonials');
        testmionial_slider.owlCarousel({
            autoplay: true,
            autoplaySpeed: 8000,
            autoplayHoverPause: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            loop:true,
            margin: 0,
            stagePadding: 0,
            items: 1,
            nav: true,
            dots: false,
            center: true,
            navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
            ],
        });



        var cookieName = 'BLCCookie'; // The cookie name
        var cookieLifetime = 90; // Cookie expiry in days

        /**
         * Set a cookie
         * @param cname - cookie name
         * @param cvalue - cookie value
         * @param exdays - expiry in days
         */
        var _setCookie = function (cname, cvalue, exdays) {
          var d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          var expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        };
        /**
         * Get a cookie
         * @param cname - cookie name
         * @returns string
         */
        var _getCookie = function (cname) {
          var name = cname + "=";
          var ca = document.cookie.split(';');
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        };
        /**
         * Should the cookie notice be shown?
         */
        var _shouldShowPopup = function () {
          if (_getCookie(cookieName)) {
            return false;
          } else {
            return true;
          }
        };
        // Show the cookie notice on load if not previously accepted
        if (_shouldShowPopup()) {
          $('#cookie-notice').addClass('show');
        }
        // Cookie dismiss btn - consent
        $('#cookie-notice .btn').on('click', function () {
          _setCookie(cookieName, 1, cookieLifetime);
        });

        // Stylise any select fields in cf7 forms
        function format(item) {
          return $(item.element).html();
        }

        $('.wpcf7-select').select2({
          formatResult: format,
          formatSelection: format,
          escapeMarkup: function (text) { return text; },
          minimumResultsForSearch: Infinity
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // cs accordion
        var acc = document.getElementsByClassName("accordion-title");
        var i;

        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
            }
          });
        }

        // hero slider
        var swiper = new Swiper('.swiper-container', {
          // direction: 'vertical',
          autoHeight: true,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          slidesPerView: 1,
          watchOverflow: true,
          loop: true,
          navigation: {
            nextEl: '.slider-arrows .slide-next',
            prevEl: '.slider-arrows .slide-prev',
          },
        });
        // pause on hover when hovering over the slide (buttons etc)
        $(".swiper-container").hover(function() {
            swiper.autoplay.stop(); // stop autoplay
        }, function() {
            swiper.autoplay.start(); // start autoplay
        });

        // height matching elements
        $(document).ready(function(){
            $('.carousel-suggested-activities .activity-grid-item').matchHeight();
            $('.carousel-footer-promos .whats-on-item').matchHeight();
        });
        $(window).resize(function() {
            $('.carousel-suggested-activities .activity-grid-item').matchHeight();
            $('.carousel-footer-promos .whats-on-item').matchHeight();
        });


      }
    },


    // Home page js
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // owl carousel - locations
        var location_slider = $('.locations-slider');
        location_slider.owlCarousel({
            autoplay: false,
            lazyLoad:true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            items: 4,
            nav: true,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            //loop: $(".home-slider .owl-item").length <= 4 ? false : true,
            loop: true,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 0,
                loop: false,
                nav: false,
                autoplay: false,
            },
            767:{
                items:2,
                margin: 20,
                loop: false,
                nav: false,
                autoplay: false,
            },
            991:{
                items:4,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        if ($(window).width() < 992) { // award carousel loads a scrollbar when on tablet/mobile only on page load
          $(".locations-slider").mCustomScrollbar({
              axis:"x",
              theme:"dark-thick",
              autoExpandScrollbar:true,
              advanced:{autoExpandHorizontalScroll:true},
              updateOnContentResize:true,
              scrollbarPosition: 'outside',
              scrollInertia: 200,
              mouseWheel: 'disable' // disable mouse control (annoying on full-width carousels as it interferes with page scroll)
          });
        }
        $(window).resize(function() { // recalculate on browser resize
          if ($(window).width() < 992) { // award carousel loads a scrollbar when on tablet/mobile only
            $(".locations-slider").mCustomScrollbar({
                axis:"x",
                theme:"dark-thick",
                autoExpandScrollbar:true,
                advanced:{autoExpandHorizontalScroll:true},
                updateOnContentResize:true,
                scrollbarPosition: 'outside',
                scrollInertia: 200,
                mouseWheel: 'disable' // disable mouse control (annoying on full-width carousels as it interferes with page scroll)
            });
          }

        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // height matching elements
        $(document).ready(function(){
            $('.featurebox-column').matchHeight();
        });
        $(window).resize(function() {
            $('.featurebox-column').matchHeight();
        });

      }
    },




    // Single activities us page.
    'single_activities': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.nav-tabs').tabCollapse();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        // Google Map on single activities
        (function($) {


          /**
           * centerMap
           *
           * Centers the map showing all markers in view.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   object The map instance.
           * @return  void
           */
          function centerMap( map ) {

              // Create map boundaries from all map markers.
              var bounds = new google.maps.LatLngBounds();
              map.markers.forEach(function( marker ){
                  bounds.extend({
                      lat: marker.position.lat(),
                      lng: marker.position.lng()
                  });
              });

              // Case: Single marker.
              if( map.markers.length === 1 ){
                  map.setCenter( bounds.getCenter() );

              // Case: Multiple markers.
              } else{
                  map.fitBounds( bounds );
              }
          }

          /**
           * initMap
           *
           * Renders a Google Map onto the selected jQuery element
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @return  object The map instance.
           */
          function initMap( $el ) {

              // Find marker elements within map.
              var $markers = $el.find('.marker');

              // Create gerenic map.
              var mapArgs = {
                  zoom        : $el.data('zoom') || 18,
                  mapTypeId   : google.maps.MapTypeId.ROADMAP,
                  styles : [
                              {
                                  "featureType": "all",
                                  "elementType": "all",
                                  "stylers": [
                                      {
                                          "saturation": -100
                                      },
                                      {
                                          "gamma": 0.5
                                      }
                                  ]
                              }
                          ]
              };
              var map = new google.maps.Map( $el[0], mapArgs );

              // Add markers.
              map.markers = [];
              $markers.each(function(){
                  initMarker( $(this), map );
              });

              // Center map based on markers.
              centerMap( map );

              // Return map instance.
              return map;
          }

          /**
           * initMarker
           *
           * Creates a marker for the given jQuery element and map.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @param   object The map instance.
           * @return  object The marker instance.
           */
          function initMarker( $marker, map ) {

              // Get position from marker.
              var lat = $marker.data('lat');
              var lng = $marker.data('lng');
              var latLng = {
                  lat: parseFloat( lat ),
                  lng: parseFloat( lng )
              };

              var icon = {
                  path: "M31.6,28.4c2.3-3,3.7-6.7,3.7-10.7C35.3,7.9,27.4,0,17.7,0C7.9,0,0,7.9,0,17.6c0,4,1.4,7.7,3.6,10.7l14,20.5L31.6,28.4z",
                  fillColor: '#e72274',
                  fillOpacity: 1,
                  anchor: new google.maps.Point(16,45),
                  strokeWeight: 0,
                  scale: 1
              };

              // create a marker with our custom icon
              var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                // icon: '/app/themes/burnleyleisure/map-markers/map-marker.svg',
                icon: icon,
                size: new google.maps.Size(32,45),
              });

              // Append to reference for later use.
              map.markers.push( marker );

              // If marker contains HTML, add it to an infoWindow.
              if( $marker.html() ){

                  // Create info window.
                  var infowindow = new google.maps.InfoWindow({
                      content: $marker.html()
                  });

                  // Show info window when marker is clicked.
                  google.maps.event.addListener(marker, 'click', function() {
                      infowindow.open( map, marker );
                  });
              }
          }
          // Render the map on page load
          $(document).ready(function(){
              $('.acf-map').each(function(){
                  var map = initMap( $(this) );
              });
          });

        })(jQuery);


        // owl carousel - single-video (not a carousel per se, but uses hte lazyload and thumbnail feature)
        var singlevid_slider = $('.carousel-video-single');
        singlevid_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            margin: 0,
            stagePadding: 0,
            items: 1,
            nav: false,
            dots: false,
            video: true,
            center: true,
        });


        // owl carousel - video gallery
        var vid_slider = $('.carousel-video-gallery');
        vid_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 100,
            items: 1,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            // lazyLoad: true,
            center: true,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        // video carousel custom scrollbar
        $(".carousel-video-gallery").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // scroll to top of open bootstrap tab
        $('#activity-tabs').on('shown.bs.tab', function () {
            var panel = $(this).find('.active');
            $('html, body').animate({
                scrollTop: panel.offset().top
            }, 200);
        });


        // owl carousel - single-photo
        var singlephoto_slider = $('.carousel-photo-single');
        singlephoto_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            margin: 0,
            stagePadding: 0,
            items: 1,
            nav: false,
            dots: false,
            center: false,
        });


        // owl carousel - photo gallery
        var photo_slider = $('.carousel-photo-gallery');
        photo_slider.owlCarousel({
            autoplay: false,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 100,
            items: 1,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            center: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // video carousel custom scrollbar
        $(".carousel-photo-gallery").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // owl carousel - photo gallery for full-width (displays more images in the row)
        var photo_slider = $('.carousel-photo-gallery-full');
        photo_slider.owlCarousel({
            autoplay: false,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 100,
            items: 3,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            center: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:3,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // photo carousel custom scrollbar
        $(".carousel-photo-gallery-full").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

        // This will create popup-image from single images
        $('.popup-img').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
        });

        // This will create a popup gallery from all photos in the gallery loop
        $('.popup-gallery').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
          gallery:{
            enabled:true
          }
        });

        // owl carousel - suggested activities
        var sugact_slider = $('.carousel-suggested-activities');
        sugact_slider.owlCarousel({
            autoplay: false,
            lazyLoad: true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            mouseDrag: true,
            touchDrag: false,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: false,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // suggested activities custom scrollbar
        $(".carousel-suggested-activities").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // height matching
        $(document).ready(function(){
            $('.fpb-equal').matchHeight();
            $('.fnpb-equal').matchHeight();
            $('.sfy-equal').matchHeight();
            $('.wpcf7-form .form-eq').matchHeight();
            $('#promo-blocks .col-lg-6').matchHeight();
        });
        $(window).resize(function() {
            $('.fpb-equal').matchHeight();
            $('.fnpb-equal').matchHeight();
            $('.sfy-equal').matchHeight();
            $('.wpcf7-form .form-eq').matchHeight();
            $('#promo-blocks .col-lg-6').matchHeight();
        });


        }
    },




     // Single Locations page
    'single_locations': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.nav-tabs').tabCollapse();
      },
      finalize: function() {
        // JavaScript to be fired on the Single Locations page, after the init JS

        // scroll to top of open bootstrap tabs
        $('#location-tabs').on('shown.bs.tab', function () {
            var panel = $(this).find('.active');
            $('html, body').animate({
                scrollTop: panel.offset().top
            }, 200);
        });

        // Select2 formatting
        function format (item) {
            return $(item.element).html();
        }
        // Select2 Targeting
        $('#shows select, .single-location-timetable-xn select').select2({
            formatResult: format,
            formatSelection: format,
            escapeMarkup: function (text) { return text; },
            minimumResultsForSearch: Infinity
        });

        // Google Map on locations
        (function($) {

          /**
           * centerMap
           *
           * Centers the map showing all markers in view.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   object The map instance.
           * @return  void
           */
          function centerMap( map ) {

              // Create map boundaries from all map markers.
              var bounds = new google.maps.LatLngBounds();
              map.markers.forEach(function( marker ){
                  bounds.extend({
                      lat: marker.position.lat(),
                      lng: marker.position.lng()
                  });
              });

              // Case: Single marker.
              if( map.markers.length === 1 ){
                  map.setCenter( bounds.getCenter() );

              // Case: Multiple markers.
              } else{
                  map.fitBounds( bounds );
              }
          }
          /**
           * initMap
           *
           * Renders a Google Map onto the selected jQuery element
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @return  object The map instance.
           */
          function initMap( $el ) {

              // Find marker elements within map.
              var $markers = $el.find('.marker');

              // Create gerenic map.
              var mapArgs = {
                  zoom        : $el.data('zoom') || 18,
                  mapTypeId   : google.maps.MapTypeId.ROADMAP,
                  styles : [
                              {
                                  "featureType": "all",
                                  "elementType": "all",
                                  "stylers": [
                                      {
                                          "saturation": -100
                                      },
                                      {
                                          "gamma": 0.5
                                      }
                                  ]
                              }
                          ]
              };
              var map = new google.maps.Map( $el[0], mapArgs );

              // Add markers.
              map.markers = [];
              $markers.each(function(){
                  initMarker( $(this), map );
              });

              // Center map based on markers.
              centerMap( map );

              // Return map instance.
              return map;
          }
          /**
           * initMarker
           *
           * Creates a marker for the given jQuery element and map.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @param   object The map instance.
           * @return  object The marker instance.
           */
          function initMarker( $marker, map ) {

              // Get position from marker.
              var lat = $marker.data('lat');
              var lng = $marker.data('lng');
              var latLng = {
                  lat: parseFloat( lat ),
                  lng: parseFloat( lng )
              };

              var icon = {
                  path: "M31.6,28.4c2.3-3,3.7-6.7,3.7-10.7C35.3,7.9,27.4,0,17.7,0C7.9,0,0,7.9,0,17.6c0,4,1.4,7.7,3.6,10.7l14,20.5L31.6,28.4z",
                  fillColor: '#e72274',
                  fillOpacity: 1,
                  anchor: new google.maps.Point(16,45),
                  strokeWeight: 0,
                  scale: 1
              };

              // create a marker with our custom icon
              var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                // icon: '/app/themes/burnleyleisure/map-markers/map-marker.svg',
                icon: icon,
                size: new google.maps.Size(32,45),
              });

              // Append to reference for later use.
              map.markers.push( marker );

              // If marker contains HTML, add it to an infoWindow.
              if( $marker.html() ){

                  // Create info window.
                  var infowindow = new google.maps.InfoWindow({
                      content: $marker.html()
                  });

                  // Show info window when marker is clicked.
                  google.maps.event.addListener(marker, 'click', function() {
                      infowindow.open( map, marker );
                  });
              }
          }

          // Render the map on page load
          $(document).ready(function(){
              $('.acf-map').each(function(){
                  var map = initMap( $(this) );
              });
          });

        })(jQuery);



        // owl carousel - single-video (not a carousel per se, but uses hte lazyload and thumbnail feature)
        var singlevid_slider = $('.carousel-video-single');
        singlevid_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            margin: 0,
            stagePadding: 0,
            items: 1,
            nav: false,
            dots: false,
            video: true,
            center: true,
        });


        // owl carousel - video gallery
        var vid_slider = $('.carousel-video-gallery');
        vid_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 100,
            items: 1,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            // lazyLoad: true,
            center: true,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // video carousel custom scrollbar
        $(".carousel-video-gallery").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // scroll to top of open bootstrap tab
        $('#activity-tabs').on('shown.bs.tab', function () {
            var panel = $(this).find('.active');
            $('html, body').animate({
                scrollTop: panel.offset().top
            }, 200);
        });

        // owl carousel - single-photo
        var singlephoto_slider = $('.carousel-photo-single');
        singlephoto_slider.owlCarousel({
            autoplay: false,
            merge:true,
            loop:false,
            margin: 0,
            stagePadding: 0,
            items: 1,
            nav: false,
            dots: false,
            center: false,
        });

        // owl carousel - photo gallery
        var photo_slider = $('.carousel-photo-gallery');
        photo_slider.owlCarousel({
            autoplay: false,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 100,
            items: 1,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            center: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // video carousel custom scrollbar
        $(".carousel-photo-gallery").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

        // owl carousel - suggested activities
        var sugloc_slider = $('.carousel-suggested-activities');
        sugloc_slider.owlCarousel({
            autoplay: false,
            lazyLoad: true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            mouseDrag: true,
            touchDrag: false,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: false,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        // suggested activities custom scrollbar
        $(".carousel-suggested-activities").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // This will create popup-image from single images
        $('.popup-img').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
        });

        // This will create popup gallery from all photos in the gallery loop
        $('.popup-gallery').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
          gallery:{
            enabled:true
          }
        });

        // height matching
        $(window).ready(function() {
          $('#activities-grid .activity-grid-item h4.activity-title').matchHeight();
          $('#activities-grid .activity-grid-item').matchHeight({byRow:false});
          $('#activities-grid .activities-grid .activity-item').matchHeight();
          $('.promo-block.width-50 .promo-content-holder').matchHeight();
          //$('.sfy-equal').matchHeight();
          $('.location-grid-item h4.location-title').matchHeight();
          $('.location-grid-item').matchHeight({byRow:false});
          $('#activity-suggested-bar .suggested-item .activity-item').matchHeight();
        });
        $(window).resize(function() {
          $('#activities-grid .activity-grid-item h4.activity-title').matchHeight();
          $('#activities-grid .activity-grid-item').matchHeight({byRow:false});
          $('#activities-grid .activities-grid .activity-item').matchHeight();
          $('.promo-block.width-50 .promo-content-holder').matchHeight();
          //$('.sfy-equal').matchHeight();
          $('.location-grid-item h4.location-title').matchHeight();
          $('.location-grid-item').matchHeight({byRow:false});
          $('#activity-suggested-bar .suggested-item .activity-item').matchHeight();
        });

      }
    },




    // Our Locations
    'our_locations': {
      init: function() {
        // JavaScript to be fired on the home page

          // AJAX filter locations dropdown
          $('select[name=categoryfilter]').change(function() {
              var filter = $('#filter');
              $.ajax({
                url:filter.attr('action'),
                data:filter.serialize(), // form data
                type:filter.attr('method'), // POST
                success:function(data){
                  $('#response').html(data); // insert data
                },
                complete: function (data) {
                  $('.location-grid-item h4.location-title').matchHeight();
                  $('.location-grid-item').matchHeight();
                  $(window).resize(function() {
                    $('.location-grid-item h4.location-title').matchHeight();
                    $('.location-grid-item').matchHeight();
                  });
                 }
              });
              return false;
          });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

          // width to match heights (may not be needed in production version)
          var img_container_width = $('.location-grid-item').width();
          $('.location-grid-item').height(img_container_width);


          // height matching
          $(window).ready(function() {
            $('.location-grid-item h4.location-title').matchHeight();
            $('.location-grid-item').matchHeight();
          });
          $(window).resize(function() {
            $('.location-grid-item h4.location-title').matchHeight();
            $('.location-grid-item').matchHeight();
          });


          // Select2 formatting
          function format (item) {
            return $(item.element).html();
          }
          // Select2 Targeting
          $('#filter select').select2({
              formatResult: format,
              formatSelection: format,
              escapeMarkup: function (text) { return text; },
              minimumResultsForSearch: Infinity
          });

      }
    },



    // Activity Taxonomy
    'tax_activity_category': {
      init: function() {
        // JavaScript to be fired on the Activity Taxonomy pages

        //detect back button click and rerun listActivities based on previous URL params
        window.onpopstate = function(event)
        {
          var url_string = window.location.protocol + '//' + window.location.hostname + event.state.urlPath;
          var url = new URL(url_string);
          var page = url.searchParams.get("page");
          listActivities(page, true);
        };

          // List activities on page load.
          var url_string = window.location;
          var url = new URL(url_string);
          var page = url.searchParams.get("page");
            listActivities(page);

            // On category filter change, reload activities over AJAX.
            $('select[name=categoryFilter]').change(function() {
                listActivities();
            });

            // Regenerate activities if a page number in the pagination is clicked.
            $('body').on('click', '.pages a', function() {
                $('input[name=page]').val($(this).attr('data-page'));
                $('html, body').animate({ scrollTop: $("#activities-grid").offset().top }, 600);
                listActivities();
            });

            function getSelectedPage() {
                return $('input[name=page]').val();
            }

            function clearContent() {
                $('#response.activities-grid').empty();
                $('.pages').empty();
                $('input[name=page]').val(''); // Go back to page 1 when changing filters.
            }

            /**
            *   ==============================
            *   Perform API call to get all activities based on user input, and
            *   display activities to the front-end.
            *   ==============================
            */
            function replaceQueryParam(param, newval, search) {
              var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
              var query = search.replace(regex, "$1").replace(/&$/, '');

              return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
            }

            function listActivities(query_page, from_history) {
              /* Get current user input values */
                var filter = $('#filter');
                if (query_page){
                  var page = query_page;
                }else{
                  var page = getSelectedPage();
                }

                //detect if user clicks back button and assign from_history (bool)
                if (from_history){
                  var from_history = true;
                }else{
                  var from_history = false;
                }

                /* Perform GetEvents API call and populate timetable with data. */
                $.ajax({
                    url: filter.attr('action'),
                    data: filter.serialize() + '&page=' + page + '&from_history=' + from_history, // form data
                    type: filter.attr('method'), // POST
                    success:function(data) {
                        clearContent(); // Clear any existing data.
                        /* Add pagination, highlighting currently selected page. */
                        for (var i = 1; i <= data.meta.pages; i++) {
                            var active = '';
                            if (i == data.meta.currentPage) {
                              active = 'class="active"';
                              active_page = i;
                            } // highlight current page.
                            $('.pages').append('<a ' + active + ' data-page=' + i + '>' + i + '</a>');
                        }

                        if (data.meta.total > 0) { // For each activities found, display to the front-end.
                          // if(data.current_activity.slug !== 'things-to-do'){
                            // change page title based on current activity response
                            $('.page-header-title').html(data.current_activity.name);
                            //update URL 'filter' parameter
                            if(data.from_history === "false"){
                              const url = '/activities/things-to-do/?filter=' + data.current_activity.slug + '&page=' + data.meta.currentPage;
                              window.history.pushState({urlPath: url},"",url);
                            }
                          // }

                          $(data.results).each(function(index, activity) {
                                $('#response').append(activity.html);
                            });
                        } else { // No activities found matching selected criteria.
                            $('#response').append('No activities found that match your criteria.');
                        }
                    },
                    complete: function (data) {
                        $('.location-grid-item h4.location-title').matchHeight();
                        $('.location-grid-item').matchHeight({byRow:false});
                        $(window).resize(function() {
                            $('.location-grid-item h4.location-title').matchHeight();
                            $('.location-grid-item').matchHeight({byRow:false});
                        });
                        $(window).ready(function() {
                          $('.activity-grid-item h4.activity-title').matchHeight();
                          $('.activity-grid-item').matchHeight({byRow:false});
                          $('.activities-grid .activity-item').matchHeight();
                        });
                        $(window).resize(function() {
                          $('.activity-grid-item h4.activity-title').matchHeight();
                          $('.activity-grid-item').matchHeight({byRow:false});
                          $('.activities-grid .activity-item').matchHeight();
                        });
                    }
                });
            }


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

          // width to match heights (may not be needed in production version)
          var img_container_width = $('.activity-grid-item').width();
          $('.activity-grid-item').height(img_container_width);


          // height matching
          $(window).ready(function() {
            $('.activity-grid-item h4.activity-title').matchHeight();
            $('.activity-grid-item').matchHeight({byRow:false});
          });
          $(window).resize(function() {
            $('.activity-grid-item h4.activity-title').matchHeight();
            $('.activity-grid-item').matchHeight({byRow:false});
          });


          // Select2 formatting
          function format (item) {
            return $(item.element).html();
          }
          // Select2 Targeting
          $('#filter select').select2({
              formatResult: format,
              formatSelection: format,
              escapeMarkup: function (text) { return text; },
              minimumResultsForSearch: Infinity
          });


      }
    },


    // Things to do page
    'things_to_do': {
      init: function() {
            // List activities on page load.
            listActivities();

            // On category filter change, reload activities over AJAX.
            $('select[name=categoryFilter]').change(function() {
                listActivities();
            });

            // Regenerate activities if a page number in the pagination is clicked.
            $('body').on('click', '.pages a', function() {
                $('input[name=page]').val($(this).attr('data-page'));
                $('html, body').animate({ scrollTop: $("#activities-grid").offset().top }, 600);
                listActivities();
            });

            function getSelectedPage() {
                return $('input[name=page]').val();
            }

            function clearContent() {
                $('#response.activities-grid').empty();
                $('.pages').empty();
                $('input[name=page]').val(''); // Go back to page 1 when changing filters.
            }

            /**
            *   ==============================
            *   Perform API call to get all activities based on user input, and
            *   display activities to the front-end.
            *   ==============================
            */
            function listActivities() {
                /* Get current user input values */
                var filter = $('#filter');
                var page = getSelectedPage();

                /* Perform GetEvents API call and populate timetable with data. */
                $.ajax({
                    url: filter.attr('action'),
                    data: filter.serialize() + '&page=' + page, // form data
                    type: filter.attr('method'), // POST
                    success:function(data) {
                        clearContent(); // Clear any existing data.
                        /* Add pagination, highlighting currently selected page. */
                        for (var i = 1; i <= data.meta.pages; i++) {
                            var active = '';
                            if (i == data.meta.currentPage) { active = 'class="active"'; } // highlight current page.
                            $('.pages').append('<a ' + active + ' data-page=' + i + '>' + i + '</a>');
                        }

                        if (data.meta.total > 0) { // For each activities found, display to the front-end.
                            $(data.results).each(function(index, activity) {
                                $('#response').append(activity.html);
                            });
                        } else { // No activities found matching selected criteria.
                            $('#response').append('No activities found that match your criteria.');
                        }
                    },
                    complete: function (data) {
                        $('.location-grid-item h4.location-title').matchHeight();
                        $('.location-grid-item').matchHeight({byRow:false});
                        $(window).resize(function() {
                            $('.location-grid-item h4.location-title').matchHeight();
                            $('.location-grid-item').matchHeight({byRow:false});
                        });
                        $(window).ready(function() {
                          $('.activity-grid-item h4.activity-title').matchHeight();
                          $('.activity-grid-item').matchHeight({byRow:false});
                          $('.activities-grid .activity-item').matchHeight();
                        });
                        $(window).resize(function() {
                          $('.activity-grid-item h4.activity-title').matchHeight();
                          $('.activity-grid-item').matchHeight({byRow:false});
                          $('.activities-grid .activity-item').matchHeight();
                        });
                    }
                });
            }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

          $(window).ready(function() {
            $('.activity-grid-item h4.activity-title').matchHeight();
            $('.activity-grid-item').matchHeight({byRow:false});
            $('.activities-grid .activity-item').matchHeight();
          });
          $(window).resize(function() {
            $('.activity-grid-item h4.activity-title').matchHeight();
            $('.activity-grid-item').matchHeight({byRow:false});
            $('.activities-grid .activity-item').matchHeight();
          });

          // Select2 formatting
          function format (item) {
            return $(item.element).html();
          }
          // Select2 Targeting #categoryfilter select
          $('#filter select').select2({
              formatResult: format,
              formatSelection: format,
              escapeMarkup: function (text) { return text; },
              minimumResultsForSearch: Infinity
          });



      }
    },


    // Memberships & Prices
    'memberships': {
      init: function() {
        // JavaScript to be fired on the Memberships & Prices page
        $('.nav-tabs').tabCollapse();

        // scroll to top of open bootstrap tab
          $('#membership-tabs').on('shown.bs.tab', function () {
              var panel = $(this).find('.active');
              $('html, body').animate({
                  scrollTop: panel.offset().top
              }, 200);
          });

          // handles the excerpt in the plan being hidden when the full info is requested
          $('.membership-more').on('show.bs.collapse', function (e) {
             $(e.target).prev('.membershipintro-excerpt').addClass('hidden');
          }).on('hide.bs.collapse', function (e) {
             $(e.target).prev('.membershipintro-excerpt').removeClass('hidden');
          });

          $('.membership-more').on('shown.bs.collapse', function (e) {
             $(e.target).prev('.membershipintro-excerpt').addClass('hidden');
          }).on('hidden.bs.collapse', function (e) {
             $(e.target).prev('.membershipintro-excerpt').removeClass('hidden');
          });



          // start wizard code //

          // using jquery-steps to handle the overall wizard functionality
          $("#wizard").steps({
                headerTag: "h4",
                bodyTag: "section",
                transitionEffect: "fade",
                enableAllSteps: true,
                onStepChanging: function (event, currentIndex, newIndex) {  // handles progress bar
                    if ( newIndex === 1 ) {
                        $('.wizard > .steps ul').addClass('step-2');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-2');
                    }
                    if ( newIndex === 2 ) {
                        $('.wizard > .steps ul').addClass('step-3');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-3');
                    }
                    if ( newIndex === 3 ) {
                        $('.wizard > .steps ul').addClass('step-4');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-4');
                    }
                    if ( newIndex === 4 ) {
                        $('.wizard > .steps ul').addClass('step-5');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-5');
                    }
                    if ( newIndex === 5 ) {
                        $('.wizard > .steps ul').addClass('step-6');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-6');
                    }
                    return true;
                },
                labels: {
                    next: "Continue",
                    previous: "Back"
                }
            });
            // Custom Button Jquery Steps
            $('.forward').click(function(){
              $("#wizard").steps('next');
            });
            $('.backward').click(function(){
              $("#wizard").steps('previous');
            });

            // set the preferred price choice from the last step to pass to the final membership results
            var preferred_price;

            // on click of wizard step btn that holds the radio field
            $('.checkbox-circle .btn').on('click', function(e) {
                // prevent the next step index from actioning - otherwise causes 2x steps ahead when clicked
                e.preventDefault();
                // find the radio button in this btn
                $(this).find(':input').prop('checked',true);
                if ($(this).find(':input').is(':checked')) {
                  $(this).addClass('radio-selected');
                }
                // Remove .radio-selected from an answer if the user goes back and changes their selected radio. (i.e. select all radio buttons where not selected and remove class.)
                $('.checkbox-circle .btn input[type=radio]:not(:checked)').parent().removeClass('radio-selected');
                // proceed to next step
                if (!$(this).parent().hasClass('last-step')) {
                  $("#wizard").steps('next');
                }
                if ($(this).parent().hasClass('last-step')) {
                  e.preventDefault();
                  preferred_price = $(this).find(':input').val();
                  listMemberships(preferred_price);
                  $("#wizard").steps('next');
                }
            });


            function clearMembershipContent() {
                $('#wizardresponse').empty();
            }


            /**
            *   ==============================
            *   Perform API call to get all memberships posts based on user input in the wizard, and
            *   display qualified to the front-end.
            *   ==============================
            */
            function listMemberships(preferred_price) {
                /* Get current user input values */
                var wizard = $('#membershipwizard');

                /* Perform GetEvents API call and populate qualifying memberships with data. */
                $.ajax({
                    url: wizard.attr('action'),
                    data: wizard.serialize(), // form data
                    type: wizard.attr('method'), // POST
                    beforeSend: function() {
                      clearMembershipContent();
                      $('#loading').show();
                    },
                    success:function(data) {
                        clearMembershipContent();
                        $(data).each(function(index, membership) {
                            $('#wizardresponse').append(membership.html);
                        });
                        hidePrices(preferred_price);
                    },
                    complete: function (data) {
                        $('#loading').hide();
                        $('.owl-item .membership-card').matchHeight();
                        $(window).resize(function() {
                          $('.owl-item .membership-card').matchHeight();
                        });

                        // owl carousel - memberships
                        var memberships_slider = $('.carousel-wizardmemberships');
                        memberships_slider.trigger('destroy.owl.carousel');
                        memberships_slider.owlCarousel({
                            autoplay: false,
                            lazyLoad:true,
                            navSpeed: 400,
                            margin: 0,
                            stagePadding: 0,
                            items: 1,
                            nav: true,
                            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
                            loop: false,
                            dots: false
                        });

                    }
                });
            }

            function hidePrices(preferred_price) {
                console.log('selected : ' + preferred_price);
                if (preferred_price == 'monthly-dd' || preferred_price == 'monthly-cancel') {
                  $('.membership-prices .annually').empty();
                } else if (preferred_price == 'annually') {
                  $('.membership-prices .monthly').empty();
                }
            }

            /* end wizard code */


      },
      finalize: function() {
        // JavaScript to be fired on the Memberships & Prices page, after the init J


      }
    },




    // Blog parent page
    'blog': {
      init: function() {

        // Load all blog posts on page load.
        listBlogPosts();

        // On filter changes, reload posts over AJAX.
        $('select[name=categoryId]').change(function() {
            listBlogPosts();
        });

        // Regenerate posts if a page number in the pagination is clicked.
        $('body').on('click', '.pages a', function() {
            $('input[name=page]').val($(this).attr('data-page'));
            $('html, body').animate({ scrollTop: $("#blog-filter").offset().top }, 600);
            listBlogPosts();
        });

        function getSelectedPage() {
            return $('input[name=page]').val();
        }

        // Return all the posts to the front-end.
        function listBlogPosts() {
            var filter = $('#filter');
            var page = getSelectedPage();
            $.ajax({
                url: filter.attr('action'),
                data: filter.serialize() + '&page=' + page, // form data
                type: filter.attr('method'),
                success:function(data) {

                    clearPosts(); // Clear any existing data.

                    // change page title based on current activity response
                    //update URL 'filter' parameter
                    const url = '/blog/?filter=' + data.current_category.slug;
                    window.history.pushState({urlPath: url},"",url);

                    /* Add pagination, highlighting currently selected page. */
                    for (var i = 1; i <= data.meta.pages; i++) {
                        var active = '';
                        if (i == data.meta.currentPage) { active = 'class="active"'; } // highlight current page.
                        $('.pages').append('<a ' + active + ' data-page=' + i + '>' + i + '</a>');
                    }

                    if (data.meta.total > 0) { // For each events found, build card on the front-end.
                        $(data.results).each(function(index, post) {
                            $('#response').append(post.html);
                        });
                    } else { // No posts found matching selected criteria.
                        $('.events').append('No blog posts found.');
                    }
                }
            });
        }

        function clearPosts() {
            $('#response').empty();
            $('.pages').empty();
            $('input[name=page]').val(''); // Go back to page 1 when changing filters.
        }

      },
      finalize: function() {
        // JavaScript to be fired on the Blog parent page, after the init J

        // height matching all items, not just in the same row
        $(window).ready(function() {
          $('.blog-item .blog-grid-item').matchHeight({byRow:false});
        });
        $(window).resize(function() {
          $('.blog-item .blog-grid-item').matchHeight({byRow:false});
        });

        // Select2 formatting
        function format (item) {
          return $(item.element).html();
        }
        // Select2 Targeting
        $('#filter select').select2({
            formatResult: format,
            formatSelection: format,
            escapeMarkup: function (text) { return text; },
            minimumResultsForSearch: Infinity
        });

      }
    },




    // Contact
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the contact page

        // scroll to top of open bootstrap accordion
        $('#common-questions .panel-collapse').on('shown.bs.collapse', function (e) {
            var $panel = $(this).closest('.panel');
            $('html,body').animate({
                scrollTop: $panel.offset().top
            }, 200);
        });


      },
      finalize: function() {
        // JavaScript to be fired on the contact page, after the init JS

        $('#common-questions .no-overlay-modal').on('show.bs.collapse', function (e) {
            $('.topic-questions-list').addClass('absolute');
        })

        // reposition the answer modal when close button clicked
        $(".close-modal").click(function(){
            $('.topic-questions-list').removeClass('absolute');
        });
        // reset and remove absolute position of inner modal and close inner collapse when parent panel is closed
        $('#common-questions .panel-collapse').on('hide.bs.collapse', function (e) {
            $('.topic-questions-list').removeClass('absolute');
            $(this).find('.no-overlay-modal').removeClass('in');
        });

        // height matching
        $(document).ready(function(){
            $('.wpcf7-form .form-eq').matchHeight();
        });
        $(window).resize(function() {
            $('.wpcf7-form .form-eq').matchHeight();
        });

        // Select2 formatting
        function format (item) {
          return $(item.element).html();
        }
        // Select2 Targeting
        $('.wpcf7-select,.centre-dropdown').select2({
            formatResult: format,
            formatSelection: format,
            escapeMarkup: function (text) { return text; },
            minimumResultsForSearch: Infinity
        });

        // change tab content based on 'select centre' dropdown is chosen
        $('.centre-dropdown').on('change', function (e) {
            $('.tab-pane').hide();
            $('.tab-pane').eq($(this).val()).show();
        });


        // Google Map on locations
        (function($) {
          /**
           * centerMap
           *
           * Centers the map showing all markers in view.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   object The map instance.
           * @return  void
           */
          function centerMap( map ) {

              // Create map boundaries from all map markers.
              var bounds = new google.maps.LatLngBounds();
              map.markers.forEach(function( marker ){
                  bounds.extend({
                      lat: marker.position.lat(),
                      lng: marker.position.lng()
                  });
              });

              // Case: Single marker.
              if( map.markers.length === 1 ){
                  map.setCenter( bounds.getCenter() );

              // Case: Multiple markers.
              } else{
                  map.fitBounds( bounds );
              }
          }
          /**
           * initMap
           *
           * Renders a Google Map onto the selected jQuery element
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @return  object The map instance.
           */
          function initMap( $el ) {

              // Find marker elements within map.
              var $markers = $el.find('.marker');

              // Create gerenic map.
              var mapArgs = {
                  zoom        : $el.data('zoom') || 18,
                  mapTypeId   : google.maps.MapTypeId.ROADMAP,
                  styles : [
                              {
                                  "featureType": "all",
                                  "elementType": "all",
                                  "stylers": [
                                      {
                                          "saturation": -100
                                      },
                                      {
                                          "gamma": 0.5
                                      }
                                  ]
                              }
                          ]
              };
              var map = new google.maps.Map( $el[0], mapArgs );

              // Add markers.
              map.markers = [];
              $markers.each(function(){
                  initMarker( $(this), map );
              });

              // Center map based on markers.
              centerMap( map );

              // Return map instance.
              return map;
          }
          /**
           * initMarker
           *
           * Creates a marker for the given jQuery element and map.
           *
           * @date    22/10/19
           * @since   5.8.6
           *
           * @param   jQuery $el The jQuery element.
           * @param   object The map instance.
           * @return  object The marker instance.
           */
          function initMarker( $marker, map ) {

              // Get position from marker.
              var lat = $marker.data('lat');
              var lng = $marker.data('lng');
              var latLng = {
                  lat: parseFloat( lat ),
                  lng: parseFloat( lng )
              };

              var icon = {
                  path: "M31.6,28.4c2.3-3,3.7-6.7,3.7-10.7C35.3,7.9,27.4,0,17.7,0C7.9,0,0,7.9,0,17.6c0,4,1.4,7.7,3.6,10.7l14,20.5L31.6,28.4z",
                  fillColor: '#e72274',
                  fillOpacity: 1,
                  anchor: new google.maps.Point(16,45),
                  strokeWeight: 0,
                  scale: 1
              };

              // create a marker with our custom icon
              var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                // icon: '/app/themes/burnleyleisure/map-markers/map-marker.svg',
                icon: icon,
                size: new google.maps.Size(32,45),
              });

              // Append to reference for later use.
              map.markers.push( marker );

              // If marker contains HTML, add it to an infoWindow.
              if( $marker.html() ){

                  // Create info window.
                  var infowindow = new google.maps.InfoWindow({
                      content: $marker.html()
                  });

                  // Show info window when marker is clicked.
                  google.maps.event.addListener(marker, 'click', function() {
                      infowindow.open( map, marker );
                  });
              }
          }

          // Render the map on page load
          $(document).ready(function(){
              $('.acf-map').each(function(){
                  var map = initMap( $(this) );
              });
          });

        })(jQuery);

      }
    },





    // About page
    'about': {
      init: function() {
        // JavaScript to be fired on the About page


        // owl carousel - locations
        var location_slider = $('.locations-slider');
        location_slider.owlCarousel({
            autoplay: false,
            lazyLoad:true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            items: 4,
            nav: true,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: $(".home-slider .owl-item").length <= 4 ? false : true,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:4,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        // height matching elements
        $(document).ready(function(){
            $('.featurebox-column').matchHeight();
            $('.locations-slider .location-title').matchHeight();
        });
        $(window).resize(function() {
            $('.featurebox-column').matchHeight();
            $('.locations-slider .location-title').matchHeight();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J


      }
    },




    // Single Blog Post
    'single_post': {
      init: function() {
        // JavaScript to be fired on the Single Blog Post page

        // owl carousel - photo gallery
        var photo_slider = $('.carousel-photo-gallery');
        photo_slider.owlCarousel({
            autoplay: false,
            loop:false,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            dots: false,
            video: true,
            center: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });
        // video carousel custom scrollbar
        $(".carousel-photo-gallery").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

        // This will create popup-image from single images
        $('.popup-img').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
        });

        // This will create popup gallery from all photos in the loop
        $('.popup-gallery').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
              titleSrc: function(item) {
                  return item.el.attr('title');
              }
          },
          gallery:{
            enabled:true
          }
        });


        // owl carousel - suggested activities
        var sugloc_slider = $('.carousel-suggested-activities');
        sugloc_slider.owlCarousel({
            autoplay: false,
            lazyLoad: true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            mouseDrag: true,
            touchDrag: false,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            loop: false,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        // suggested activities custom scrollbar
        $(".carousel-suggested-activities").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J

        // height matching
        $(document).ready(function(){
            $('.sfy-equal .owl-item').matchHeight();
        });
        $(window).resize(function() {
            $('.sfy-equal .owl-item').matchHeight();
        });

      }
    },




    // Page templates - will auto apply to any new landing pages
    'page_template': {
      init: function() {
        // JavaScript to be fired on the Holiday Club Booking page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J
        $(window).ready(function() {
          $('.activity-grid-item h4.activity-title').matchHeight();
          $('.activity-grid-item').matchHeight({byRow:false});
          $('.activities-grid .activity-item').matchHeight();
          $('.featurebox-column').matchHeight();
        });
        $(window).resize(function() {
          $('.activity-grid-item h4.activity-title').matchHeight();
          $('.activity-grid-item').matchHeight({byRow:false});
          $('.activities-grid .activity-item').matchHeight();
          $('.featurebox-column').matchHeight();
        });

      }
    },

    'page_template_default': {
      init: function() {
        // JavaScript to be fired on the Holiday Club Booking page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J
        $(window).ready(function() {
          $('.featurebox-column').matchHeight();
        });
        $(window).resize(function() {
          $('.featurebox-column').matchHeight();
        });

      }
    },




    // Holiday Club Booking page
    'holiday_club_online_booking': {
      init: function() {
        // JavaScript to be fired on the Holiday Club Booking page

        // scroll to top of open bootstrap accordion
        $('#common-questions .panel-collapse').on('shown.bs.collapse', function (e) {
            var $panel = $(this).closest('.panel');
            $('html,body').animate({
                scrollTop: $panel.offset().top
            }, 200);
        });

        // hide the inner question / answer modal when parent closes
        $('#common-questions .panel-collapse').on('hide.bs.collapse', function (e) {
            $('.topic-questions-list').removeClass('absolute');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J

        // change default behaviour of BS modals for common questions
        $(".no-overlay").click(function(){
          $('.modal-backdrop.in').hide();
          $('.topic-questions-list').addClass('absolute');
        });
        // reposition the answer modal when close button clicked
        $(".close-modal").click(function(){
          $('.topic-questions-list').removeClass('absolute');
        });

      }
    },





    // Memberships Wizard page
    'memberships_wizard': {
      init: function() {
        // JavaScript to be fired on the Memberships Wizard page

          // using jquery-steps to handle the overall wizard functionality
          $("#wizard").steps({
                headerTag: "h4",
                bodyTag: "section",
                transitionEffect: "fade",
                enableAllSteps: true,
                onStepChanging: function (event, currentIndex, newIndex) {  // handles progress bar
                    if ( newIndex === 1 ) {
                        $('.wizard > .steps ul').addClass('step-2');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-2');
                    }
                    if ( newIndex === 2 ) {
                        $('.wizard > .steps ul').addClass('step-3');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-3');
                    }
                    if ( newIndex === 3 ) {
                        $('.wizard > .steps ul').addClass('step-4');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-4');
                    }
                    if ( newIndex === 4 ) {
                        $('.wizard > .steps ul').addClass('step-5');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-5');
                    }
                    if ( newIndex === 5 ) {
                        $('.wizard > .steps ul').addClass('step-6');
                    } else {
                        $('.wizard > .steps ul').removeClass('step-6');
                    }
                    return true;
                },
                labels: {
                    next: "Continue",
                    previous: "Back"
                }
            });
            // Custom Button Jquery Steps
            $('.forward').click(function(){
              $("#wizard").steps('next');
            });
            $('.backward').click(function(){
              $("#wizard").steps('previous');
            });

            // set the preferred price choice from the last step to pass to the final membership results
            var preferred_price;

            // on click of wizard step btn that holds the radio field
            $('.checkbox-circle .btn').on('click', function(e) {
                // prevent the next step index from actioning - otherwise causes 2x steps ahead when clicked
                e.preventDefault();
                // find the radio button in this btn
                $(this).find(':input').prop('checked',true);
                if ($(this).find(':input').is(':checked')) {
                  $(this).addClass('radio-selected');
                }
                // Remove .radio-selected from an answer if the user goes back and changes their selected radio. (i.e. select all radio buttons where not selected and remove class.)
                $('.checkbox-circle .btn input[type=radio]:not(:checked)').parent().removeClass('radio-selected');
                // proceed to next step
                if (!$(this).parent().hasClass('last-step')) {
                  $("#wizard").steps('next');
                }
                if ($(this).parent().hasClass('last-step')) {
                  e.preventDefault();
                  preferred_price = $(this).find(':input').val();
                  listMemberships(preferred_price);
                  $("#wizard").steps('next');
                }
            });


            function clearMembershipContent() {
                $('#wizardresponse').empty();
            }


            /**
            *   ==============================
            *   Perform API call to get all memberships posts based on user input in the wizard, and
            *   display qualified to the front-end.
            *   ==============================
            */
            function listMemberships(preferred_price) {
                /* Get current user input values */
                var wizard = $('#membershipwizard');

                /* Perform GetEvents API call and populate qualifying memberships with data. */
                $.ajax({
                    url: wizard.attr('action'),
                    data: wizard.serialize(), // form data
                    type: wizard.attr('method'), // POST
                    beforeSend: function() {
                      clearMembershipContent();
                      $('#loading').show();
                    },
                    success:function(data) {
                        clearMembershipContent();
                        $(data).each(function(index, membership) {
                            $('#wizardresponse').append(membership.html);
                        });
                        hidePrices(preferred_price);
                    },
                    complete: function (data) {
                        $('#loading').hide();
                        $('.owl-item .membership-card').matchHeight();
                        $(window).resize(function() {
                          $('.owl-item .membership-card').matchHeight();
                        });

                        // owl carousel - memberships
                        var memberships_slider = $('.carousel-wizardmemberships');
                        memberships_slider.trigger('destroy.owl.carousel');
                        memberships_slider.owlCarousel({
                            autoplay: false,
                            lazyLoad:true,
                            navSpeed: 400,
                            margin: 0,
                            stagePadding: 0,
                            items: 1,
                            nav: true,
                            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
                            loop: false,
                            dots: false
                        });

                    }
                });
            }

            function hidePrices(preferred_price) {
                console.log('selected : ' + preferred_price);
                if (preferred_price == 'monthly-dd' || preferred_price == 'monthly-cancel') {
                  $('.membership-prices .annually').empty();
                } else if (preferred_price == 'annually') {
                  $('.membership-prices .monthly').empty();
                }
            }


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J



      }
    },





    // Careers page
    'careers': {
      init: function() {
        // JavaScript to be fired on the Careers page


        // Select2 formatting
          function format (item) {
            return $(item.element).html();
          }
          // Select2 Targeting
          $('.careers-filter select').select2({
              formatResult: format,
              formatSelection: format,
              escapeMarkup: function (text) { return text; },
              minimumResultsForSearch: Infinity
          });

          // List activities on page load.
          listVacancies();

          // Populate salary drop-down with all available salaries.
          $.ajax({
              url: '/wp/wp-admin/admin-ajax.php',
              data: 'action=getVacancySalaries', // form data
              type: 'POST',
              success:function(data) {
                  $(data).each(function(index, salary) {
                      var newOption = new Option(salary.text, salary.value);
                      $('select[name=salary]').append(newOption);
                  });
              }
          });
          // Populate hours drop-down with all available hours.
          $.ajax({
              url: '/wp/wp-admin/admin-ajax.php',
              data: 'action=getVacancyHours',
              type: 'POST',
              success:function(data) {
                  $(data).each(function(index, hour) {
                      var newOption = new Option(hour, hour);
                      $('select[name=hours]').append(newOption);
                  });
              }
          });

          // On filter changes, reload vacancies over AJAX.
          $('#centre, #salary, #hours').change(function() {
              listVacancies();
          });

          // Regenerate vacancies if a page number in the pagination is clicked.
          $('body').on('click', '.pages a', function() {
              $('input[name=page]').val($(this).attr('data-page'));
              $('html, body').animate({ scrollTop: $("#careers-list").offset().top }, 600);
              listVacancies();
          });

          function getSelectedPage() {
              return $('input[name=page]').val();
          }

          function clearContent() {
              $('.vacancies-list').empty();
              $('.pages').empty();
              $('input[name=page]').val(''); // Go back to page 1 when changing filters.
          }

          /**
          *   ==============================
          *   Perform API call to get all activities based on user input, and
          *   display activities to the front-end.
          *   ==============================
          */
          function listVacancies() {
              /* Get current user input values */
              var filter = $('#filter');
              var page = getSelectedPage();

              /* Perform getVacancies API call and populate response div with data. */
              $.ajax({
                  url: filter.attr('action'),
                  data: filter.serialize() + '&page=' + page, // form data
                  type: filter.attr('method'), // POST
                  success:function(data) {
                      clearContent(); // Clear any existing data.
                      /* Add pagination, highlighting currently selected page. */
                      for (var i = 1; i <= data.meta.pages; i++) {
                          var active = '';
                          if (i == data.meta.currentPage) { active = 'class="active"'; } // highlight current page.
                          $('.pages').append('<a ' + active + ' data-page=' + i + '>' + i + '</a>');
                      }

                      if (data.meta.total > 0) { // For each vacancies found, display to the front-end.
                          $(data.results).each(function(index, vacancy) {
                              $('#response').append(vacancy.html);
                          });
                      } else { // No vacancies found matching selected criteria.
                          $('#response').append('No vacancies have been found that match your criteria, or no vacancies are currently available.');
                      }
                  },
                  complete: function (data) {
                      //
                  }
              });
          }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J


      }
    },




    // Hospitality page
    'page_template_page_hospitality': {
      init: function() {
        // JavaScript to be fired on the Hospitality page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init J

        // owl carousel - photo gallery
        var photo_slider = $('.carousel-photo-gallery');
        photo_slider.owlCarousel({
          autoplay: false,
          loop:false,
          navSpeed: 400,
          margin: 20,
          stagePadding: 100,
          items: 1,
          nav: false,
          navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
          dots: false,
          video: true,
          center: false,
          responsive:{
            0:{
              items:1,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            },
            767:{
              items:3,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            },
            991:{
              items:5,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            }
          }
        });
        // video carousel custom scrollbar
        $(".carousel-photo-gallery").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });


        // owl carousel - photo gallery for full-width (displays more images in the row)
        var photo_slider = $('.carousel-photo-gallery-full');
        photo_slider.owlCarousel({
          autoplay: false,
          loop:false,
          navSpeed: 400,
          margin: 20,
          stagePadding: 100,
          items: 3,
          nav: false,
          navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
          dots: false,
          video: true,
          center: false,
          responsive:{
            0:{
              items:1,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            },
            767:{
              items:2,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            },
            991:{
              items:3,
              margin: 20,
              navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
              ],
            }
          }
        });
        // photo carousel custom scrollbar
        $(".carousel-photo-gallery-full").mCustomScrollbar({
          axis:"x",
          theme:"dark-thick",
          autoExpandScrollbar:true,
          advanced:{autoExpandHorizontalScroll:true},
          updateOnContentResize:true,
          scrollbarPosition: 'outside',
          scrollInertia: 200,
          mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

        // This will create popup-image from single images
        $('.popup-img').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
            titleSrc: function(item) {
              return item.el.attr('title');
            }
          },
        });

        // This will create a popup gallery from all photos in the gallery loop
        $('.popup-gallery').magnificPopup({
          delegate: "a",
          type: 'image',
          image: {
            titleSrc: function(item) {
              return item.el.attr('title');
            }
          },
          gallery:{
            enabled:true
          }
        });

        // height matching
        $(document).ready(function(){
            $('#promo-blocks .col-lg-6').matchHeight();
        });
        $(window).resize(function() {
            $('#promo-blocks .col-lg-6').matchHeight();
        });


      }
    },




    // Search Results page
    'search_results': {
      init: function() {
        // JavaScript to be fired on the Search Results page

        // owl carousel - suggested activities
        var sugact_slider = $('.carousel-suggested-activities');
        sugact_slider.owlCarousel({
            autoplay: false,
            lazyLoad: true,
            navSpeed: 400,
            margin: 20,
            stagePadding: 0,
            mouseDrag: false,
            touchDrag: false,
            items: 2,
            nav: false,
            navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
            //loop: $(".carousel-footer-promos .owl-item").length <= 2 ? false : true,
            loop: false,
            dots: false,
            responsive:{
            0:{
                items:1,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            767:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
            },
            991:{
                items:2,
                margin: 20,
                navText: [
                '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                ],
             }
            }
        });

        // suggested activities custom scrollbar
        $(".carousel-suggested-activities").mCustomScrollbar({
            axis:"x",
            theme:"dark-thick",
            autoExpandScrollbar:true,
            advanced:{autoExpandHorizontalScroll:true},
            updateOnContentResize:true,
            scrollbarPosition: 'outside',
            scrollInertia: 200,
            mouseWheel: 'disable' // disable mouse scrolling (it's annoying on full-width carousels as interferes with page scroll)
        });

      },
      finalize: function() {
        // JavaScript to be fired on the Search Results page, after the init J

        // height matching
        $(window).ready(function() {
          $('.sr-grid-item h4.sr-title').matchHeight();
          $('.sr-grid-item').matchHeight({byRow:false});
          $('.sfy-equal').matchHeight();
          //$('.sr-grid .sr-item').matchHeight();
        });
        $(window).resize(function() {
          $('.sr-grid-item h4.sr-title').matchHeight();
          $('.sr-grid-item').matchHeight({byRow:false});
          $('.sfy-equal').matchHeight();
          //$('.sr-grid .sr-item').matchHeight();
        });

      }
    },




    // Horizons data.
    'timetables': {
        init: function() {
            /**
            *   ==============================
            *   Convert filter options which are select/drop-downs to use
            *   select2 styling.
            *   ==============================
            */
            $('select[name=centres], select[name=activities], select[name=period]').select2({
                escapeMarkup: function (text) { return text; },
                minimumResultsForSearch: Infinity
            });

            /**
            *   ==============================
            *   Define 'Event' HTML template
            *   ==============================
            */
            var template = defineEventTemplate();
            /* Clone existing template from HTML and remove from view. */
            function defineEventTemplate() {
                var eventCard, template;
                eventCard = $('.timetable .events .event');
                template = eventCard.clone();
                eventCard.remove();
                return template;
            }
            /* Function to add timetable event to timetable. */
            function addTimetableEvent(template, data) {
                // template.find('.icon').html(data.icon);
                template.find('h3').html(data.name);
                template.find('.location').html(data.location);
                template.find('.time').html(data.time);
                template.find('.description p').html(data.description);
                template.find('.book a').attr('href', data.link);
                template.appendTo('.events');
            }

            /**
            *   ==============================
            *   API Calls
            *   ==============================
            */
            /* Get all available timetable dates and place in select drop-down. */
            $.get('/wp-json/horizons/v1/getdates', function( data ) {
                var i = 0; var date;
                $(data).each(function(index, value) {
                    date = value.format.split(' ');
                    $('.dates-filter').append('<div class="day" data-value="'+ value.date +'">' + date[0] + '<span>' + date[1] + '</span>' + '</div>');
                    if (i == 0) { setDateValue(value.date); } i++; /* Set the (hidden input) date's value  as the first value returned from ajax call, i.e. the earliest day, usually today. */
                });
                buildDateOwlCarousel(); // Rebuild carousel after getting the dates.
            });

            /* Get all available centres. */
            var centres = {}; // Store all centre's in JS object, for later use in displaying on event card.
            $.get('/wp-json/horizons/v1/getcentres', function( data ) {
              var selected_centre = getSelectedCentre();
              var i = 0; var selected;
                $(data).each(function(index, value) {
                   centres[value.id] = value.name;
                    if (selected_centre !== undefined && value.id === selected_centre) {
                      selected = true;
                    }else {
                      selected = false;
                    }i++;
                    var newOption = new Option(value.name, value.id, selected, selected);
                    $('select[name=centres]').append(newOption);
                });
            });

            /* Get a list of all available event types. */
            $.get('/wp-json/horizons/v1/getlistevents', function( data ) {
                var selected_activity = getSelectedActivity();
                var selected;
                $(data).each(function(index, value) {
                    if (selected_activity !== undefined && value.id === selected_activity) {
                      selected = true;
                    } else {
                      selected = false;
                    }
                    var newOption = new Option(value.name, value.id, selected, selected);
                    $('select[name=activities]').append(newOption);
                });
            });

            const period_options = [
              {
                id: 'am',
                name: 'Morning (AM)'
              },
              {
                id: 'pm',
                name: 'Afternoon/Evening (pm)',
              }
            ];
          var selected_period = getSelectedPeriod();
          var period_selected;
              $(period_options).each(function(index, value) {
                if (selected_period !== undefined && value.id === selected_period) {
                  period_selected = true;
                } else {
                  period_selected = false;
                }
                var newOption = new Option(value.name, value.id, period_selected, period_selected);
                $('select[name=period]').append(newOption);
              });



            /**
            *   ==============================
            *   Set hidden input date value and also mark the selected date
            *   with the 'active' class.
            *   ==============================
            */
            function setDateValue(value) {
                $('.dates-filter .day').removeClass('active'); // Remove active from all days.
                $('.dates-filter .day').each(function() {
                    if ($(this).attr('data-value') == value) {
                        $(this).addClass('active'); // If selected value matches a day, mark it as active.
                    }
                });
              $('input[name=dates]').val(value);
//                $('input[name=dates]').val(value).trigger('change'); // Set hidden input value.
            }
            /**
            *   ==============================
            *   Generate user friendly date to populate on the timetable.
            *   ==============================
            */
            const ordinal = function(d) {
              if (d > 3 && d < 21) return 'th';
              switch (d % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
              }
            }
            function populateDate() {
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                var d = new Date(getSelectedDate());
                d = days[ d.getDay() ] + ' ' + d.getDate() + ordinal(d.getDate()) + ' ' + months[ d.getMonth() ] + ' ' + d.getFullYear();
                $('.selected-date').html(d);
            }
            /**
            *  ==============================
            *  Generate leisure centre name and display to the front-end when changing centres.
            *  ==============================
            */
            function populateCentre() {
                var selected = getSelectedCentre();
                if (typeof selected !== 'undefined') {
                    $('.print-centre .name').html(centres[selected]);
                } else {
                    $('.print-centre .name').html('');
                }
            }

            /**
            *   ==============================
            *   Read User Input
            *   ==============================
            */
            function getSelectedDate() {
                return $('input[name=dates]').val();
            }
            function getSelectedCentre() {
                var current_url_str = window.location;
                var current_url = new URL(current_url_str);
                var centre_id_param = current_url.searchParams.get("centre_id");

                var centre = $('select[name=centres] option:selected').val();
                if (centre == 'Location') { centre = undefined; }
                return centre_id_param ? centre_id_param : centre;
            }
            function getSelectedActivity() {
              var current_url_str = window.location;
              var current_url = new URL(current_url_str);
              var activity_id_param = current_url.searchParams.get("activity_id");

                var activity = $('select[name=activities] option:selected').val();
                if (activity == 'Activity') { activity = undefined; }
                return activity_id_param ? activity_id_param : activity;
            }
            function getSelectedPage() {
                return $('input[name=page]').val();
            }
            function getSelectedPeriod() {
                var current_url_str = window.location;
                var current_url = new URL(current_url_str);
                var period_id_param = current_url.searchParams.get("period_id");

                var period = $('select[name=period] option:selected').val();
                if (period == 'Period') { period = undefined; }
                return period_id_param ? period_id_param : period;
            }

            /**
            *   ==============================
            *   Generate/Re-generate timetable data.
            *   ==============================
            */
            /* Clear timetable data. Called before adding new timetable data. */
            function clearTimetables() {
                $('.events').empty();
                $('.pages').empty();
                $('input[name=page]').val(''); // Go back to page 1 when changing filters.
            }

            function updateUrlValues(){
              var centre = $('select[name=centres] option:selected').val();
              if (centre == 'Location') { centre = ''; }

              var activity = $('select[name=activities] option:selected').val();
              if (activity == 'Activity') { activity = ''; }

              var period = $('select[name=period] option:selected').val();
              if (period == 'Period') { period = ''; }

              const url = '/timetables/?centre_id=' + centre + '&activity_id=' + activity + '&period_id=' + period;
              window.history.pushState({urlPath: url},"",url);

              generateTimetable();
            }
            /* Generate the timetable based on current user selection. */
            function generateTimetable() {
                buildTimetable();
                populateDate();
                populateCentre();
            }
            /**
            *   ==============================
            *   Regenerate timetable data
            *   ==============================
            */
            $(function() { // Only perform these after DOM loaded.
                /* If selected date, centre or activity filter changed or applied, re-run timetable generation. */
                $('input[name=dates], select[name=centres], select[name=activities], select[name=period]').change(function() {
                  updateUrlValues();
                  //generateTimetable();
                });
                /* If a date is selected, reload the timetable with events only for the selected date. */
                $('body').on('click', '.dates-filter .day', function() {
                    setDateValue( $(this).attr('data-value') );
                    generateTimetable();
                });
                /* If dynamically loaded page hyperlink clicked, define hidden input page value, re-run timetable generation and animate smooth scrolling to the top. */
                $('body').on('click', '.pages a', function() {
                    $('input[name=page]').val($(this).attr('data-page'));
                    $('html, body').animate({ scrollTop: $("#fitness").offset().top }, 600);
                    generateTimetable();
                });

                /**
                *   Hard-fix: Set the default selected centre and trigger a change to reload timetables.
                *   Do this after a second of page load. This fixes an issue where timetable data is loaded
                *   before jQuery can mark the first centre option as selected. Without this sometimes no
                *   timetable data is returned, or the centre filter doesn't work on initial page load.
                */
                setTimeout(function() {
                  generateTimetable();
                  //$('select[name=centres]').val(getSelectedCentre()); // Select the option with a value of '1'
                  //$('select[name=centres]').trigger('change'); // Notify any JS components that the value changed
                }, 2000);
            });

            /**
            *   ==============================
            *   Build the timetable. Perform API call to get all events based on user
            *   input, and add events to the front-end.
            *   ==============================
            */
            function buildTimetable() {
                /* Get current user input values */
                var date = getSelectedDate();
                var centre = getSelectedCentre();
                var activity = getSelectedActivity();
                var page = getSelectedPage();
                var period = getSelectedPeriod();

                /* Prepare API call parameters */
                if (date != undefined) { query = 'date=' + date + '&'; }
                if (centre != undefined) { query = query + 'centre_id=' + centre + '&'; }
                if (activity != undefined) { query = query + 'activity_id=' + activity + '&'; }
                if (page != undefined) { query = query + 'page=' + page + '&'; }
                if (period != undefined) { query = query + 'period=' + period + '&'; }


                  /* Perform GetEvents API call and populate timetable with data. */
                $.get('/wp-json/horizons/v1/getevents?' + query, function( data ) {

                    clearTimetables(); // Clear any existing data.

                    /* Add pagination, highlighting currently selected page. */
                    if (data.meta.pages > 1) {
                      for (var i = 1; i <= data.meta.pages; i++) {
                          var active = '';
                          if (i == data.meta.currentPage) { active = 'class="active"'; } // highlight current page.
                          $('.pages').append('<a ' + active + ' data-page=' + i + '>' + i + '</a>');
                      }
                    }

                    if (data.meta.total > 0) { // For each events found, build card on the front-end.
                        $(data.events).each(function(index, event) {

                            addTimetableEvent(template.clone(), {
                                'icon': '<img src="/app/uploads/2020/02/'+ getEventIcon(event.name) +'.svg">',
                                'name': event.name,
                                'location': centres[event.centre_id],
                                'time': event.time,
                                'description': event.description,
                                'link': event.url,

                            });
                        });
                    } else { // No events found matching selected criteria.
                        $('.events').append('No events found that match your criteria. Please try changing your selection of centre, activity and/or date.');
                    }
                });
            }

            /**
            *   ==============================
            *   Build the owl carousel which will house all the days available
            *   for selection which have timetable data for. As the dates
            *   are dynamically loaded to the DOM, we destroy the carousel
            *   and reinitiate.
            *   ==============================
            */
            function buildDateOwlCarousel() {
                $('.dates-filter').trigger('destroy.owl.carousel');
                $('.dates-filter').owlCarousel({
                    autoplay: false,
                    lazyLoad: false,
                    navSpeed: 400,
                    margin: 5,
                    stagePadding: 0,
                    items: 5,
                    nav: true,
                    navText : ["<i class='fa fa-caret-left'></i>","<i class='fa fa-caret-right'></i>"],
                    loop: false,
                    dots: false,
                    responsive:{
                    0:{
                        items: 1,
                        margin: 0,
                        loop: false,
                        nav: true,
                        dots: false,
                        centre: true,
                        autoplay: false,
                    },
                    991:{
                        items: 5,
                        margin: 5,
                        navText: [
                        '<i class="fa fa-caret-left" aria-hidden="true"></i>',
                        '<i class="fa fa-caret-right" aria-hidden="true"></i>'
                        ],
                     }
                    }
                });

                $('.dates-filter').on('changed.owl.carousel', function(event) {
                  if( window.innerWidth < 992 ){
                    var targetDay = $('.dates-filter .owl-item').eq(event.item.index);
                    targetDay.children('.day').click();
                  }
                });
            }

            /**
            *   ==============================
            *   Assign an event with an icon, using the event's name.
            *   ==============================
            */
            function getEventIcon(name) {
              var slugify_name = name.replace(" ", "-");
              var prefix = 'icon-';
                var icons = [ // Available icons.
                    '60',
                    'aerobics',
                    'attractions-bike-tea',
                    'book-online',
                    'bookjoin',
                    'communities',
                    'contact',
                    'download',
                    'family-activities',
                    'family',
                    'football',
                    'golf',
                    'health-and-fitness',
                    'high-tea',
                    'hiit',
                    'history',
                    'holiday-club',
                    'home',
                    'kayaking',
                    'kids-swim',
                    'lane-swim',
                    'legs-bums-and-tums',
                    'location',
                    'memberships',
                    'music-1',
                    'music',
                    'news',
                    'open-pool',
                    'partners',
                    'places-to-eat',
                    'prices',
                    'spinning-bike-tea',
                    'student',
                    'swim',
                    'target',
                    'theatre',
                    'things-to-do',
                    'timetable',
                    'turtle-tot',
                    'weights-class',
                    'yoga',
                    'zumba'
                ];
                // return prefix + icons[Math.floor(Math.random()*icons.length)];
                // console.log(icons);
                return prefix + slugify_name;
            }
        },
        finalize: function() {
            //
        }
    },


  };




  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
